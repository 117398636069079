// ** Reducers Imports
import {combineReducers} from 'redux';
import layout from "./layout"
import navbar from "./navbar"
import document from "./reducers/document"

const rootReducer = combineReducers({
    document: document,
    layout: layout,
    navbar: navbar,
});

export default rootReducer
