import Types from '../types';

const initialState = {
  documentPayload: null,
  previewDocumentPayload: {},
  documentAnalysis: {},
  generatedDocumentPayload: {},
};

export default function document(state = initialState, action) {
    switch (action.type) {
        case Types.DOCUMENT.SUCCESS:
            return {
                ...state,
                documentPayload: action.payload,
            };
        case Types.DOCUMENT.ADD_NEW:
            return {
                ...state,
                documentPayload: action.payload.concat([...state.documentPayload]),
            };
        // This state is for the case when the preview document are present
        case Types.PREVIEW_DOCUMENT.UPDATE:
            return {
                ...state,
                previewDocumentPayload: action.payload,
            };
        case Types.DOCUMENT_ANALYSIS.UPDATE:
            return {
                ...state,
                documentAnalysis: action.payload,
            };
        // documents that have been recently generated
        case Types.GENERATED_DOCUMENTS:
            return {
                ...state,
                generatedDocumentPayload: action.payload,
            };
        default:
            return state;
    }
}