const Types = {
    USER: {
      SUCCESS: 'USER/SUCCESS',
    },
    DOCUMENT: {
      SUCCESS: 'DOCUMENT_SUCCESS',
      ADD_NEW: 'DOCUMENT_ADD_NEW',
    },
    PREVIEW_DOCUMENT: { UPDATE: 'PREVIEW_DOCUMENT_UPDATE' },
    DOCUMENT_ANALYSIS: { UPDATE: 'DOCUMENT_ANALYSIS_UPDATE' },
    GENERATED_DOCUMENTS: "GENERATED_DOCUMENTS",
    LOGOUT: 'LOGOUT',
  };
  
  export default Types;
  